const index = () => import("@/views/pay/index.vue");
const payment = () => import("@/views/pay/payment.vue");
const pcpay = () => import("@/views/pay/pcpay.vue");
const h5pay = () => import("@/views/pay/h5pay.vue");
const wxpay = () => import("@/views/pay/wxpay.vue");
const paySucceed = () => import("@/views/pay/paySucceed.vue");
const payWaiting = () => import("@/views/pay/payWaiting.vue");
const wxOpenId = () => import("@/views/pay/wxOpenid.vue");

export default {
  path: "/pay",
  component: index,

  children: [
    {
      path: "/",
      name: `payment`,
      meta: {
        withoutAuth: false,
        title: "支付中",
      },
      component: payment,
    },
    {
      path: "pcpay",
      name: "pcpay",
      meta: {
        withoutAuth: false,
        title: "支付中",
      },
      component: pcpay,
    },
    {
      path: "h5pay",
      name: "h5pay",
      meta: {
        withoutAuth: false,
        title: "支付中",
      },
      component: h5pay,
    },
    {
      path: "wxpay",
      name: "wxpay",
      meta: {
        withoutAuth: false,
        title: "支付中",
      },
      component: wxpay,
    },
    {
      path: "paySucceed",
      name: "paySucceed",
      meta: {
        withoutAuth: false,
        title: "支付成功",
      },
      component: paySucceed,
    },
    {
      path: "payWaiting",
      name: "payWaiting",
      meta: {
        withoutAuth: false,
        title: "支付中",
      },
      component: payWaiting,
    },
    {
      path: "wxOpenId",
      name: "wxOpenId",
      meta: {
        withoutAuth: true,
        title: "wxOpenId",
      },
      component: wxOpenId,
    },
  ],
};
