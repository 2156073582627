const index = () => import("@/views/market/index.vue");
const tutor = () => import("@/views/market/tutor.vue");
const recite = () => import("@/views/market/recite.vue");
const flexRecite = () => import("@/views/market/flexRecite.vue");
const flexQRCode = () => import("@/views/market/flexQRCode.vue");
const flexQRCodeWeiXin = () => import("@/views/market/flexQRCodeWeiXin.vue");
const flexQRCodeH5 = () => import("@/views/market/flexQRCodeH5.vue");

const wechatAuth = () => import("@/views/market/wechatAuth.vue");
export default {
  path: "/mk",
  name: "mk",
  component: index,

  children: [
    {
      path: "/",
      name: "tutor",
      meta: {
        withoutAuth: true,
        title: "免费领资料",
      },
      component: tutor,
    },
    {
      path: "recite",
      name: "recite",
      meta: {
        withoutAuth: true,
        title: "2020教师资格证必背考点",
      },
      component: recite,
    },
    {
      path: "flex-recite",
      name: `flex-recite`,
      meta: {
        withoutAuth: true,
        title: "引流背书魔术",
      },
      component: flexRecite,
    },
    {
      path: "flex-qrcode",
      name: "flex-qrcode",
      meta: {
        withoutAuth: true,
        title: "活码",
      },
      component: flexQRCode,
    },
    {
      path: "wx-qrcode",
      name: "wx-qrcode",
      meta: {
        withoutAuth: true,
        title: "活码",
      },
      component: flexQRCodeWeiXin,
    },
    {
      path: "h5-qrcode",
      name: "h5-qrcode",
      meta: {
        withoutAuth: true,
        title: "活码",
      },
      component: flexQRCodeH5,
    },
    {
      path: "wechatAuth",
      name: "wechatAuth",
      meta: {
        withoutAuth: true,
        title: "wechatAuth",
      },
      component: wechatAuth,
    },
  ],
};
