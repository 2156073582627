const index = () => import("@/views/userHomeWork/index.vue");
const homeWorkList = () => import("@/views/userHomeWork/homeWorkList.vue");
const homeWorkAddEdit = () => import("@/views/userHomeWork/homeWorkAddEdit.vue");
const homeWorkView = () => import("@/views/userHomeWork/homeWorkView.vue");

export default {
  path: "/userHomeWork",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  children: [
    {
      path: "",
      name: `user-home-work-index`,
      meta: {
        withoutAuth: false,
        title: "作业-练习",
      },
      component: homeWorkList,
    },
    {
      path: "addEdit",
      name: `user-home-work-add-edit`,
      meta: {
        withoutAuth: false,
        title: "作业-练习",
      },
      component: homeWorkAddEdit,
    },
    {
      path: "view",
      name: `user-home-work-view`,
      meta: {
        withoutAuth: false,
        title: "作业-练习",
      },
      component: homeWorkView,
    },
  ],
};
