const index = () => import("@/views/homework/index.vue");
const homeWorkAssign = () => import("@/views/homework/homeworkAssign.vue");
const homeWorkDetail = () => import("@/views/homework/homeworkDetail.vue");

export default {
  path: "/jzms-homework",
  component: index,
  children: [
    {
      path: "",
      name: `jzms-homework-index`,
      meta: {
        withoutAuth: false,
        title: "爱答教育科技 教资面试",
      },
      component: homeWorkAssign,
    },
    {
      path: "detail",
      name: `jzms-homework-detail`,
      meta: {
        withoutAuth: false,
        title: "爱答教育科技 教资面试",
      },
      component: homeWorkDetail,
    },
  ],
};
