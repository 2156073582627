import Vue from "vue"; // （1）框架 = 数据双向绑定、页面的生命周期、路由
import App from "./App.vue";
import router from "./router"; // (3) 路由
import "vant/lib/icon/local.css";
import Vant from "vant";
import "vant/lib/index.less";
import { eventBus } from "@/components/eventBus";
const MarkdownIt = require("markdown-it")({});

Vue.prototype.$EventBus = eventBus;
Vue.prototype.$TcPlayer = window.TCPlayer;
Vue.prototype.$markdownIt = MarkdownIt;
Vue.prototype.$countdownSeconds = 60;

Vue.use(Vant);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
