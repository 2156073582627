import { Notify } from "vant";
import ajax from "./ajax.js";
import router from "@/router";

let base = process.env.VUE_APP_BASE_URL;

const smscode = (phone) => {
  return ajax.post(`${base}/api/itedu/v1/smscode?phone=${phone}`).then((ret) => {
    Notify({
      type: "success",
      message: "验证码获取成功",
    });
  });
};

const quicklogin = (phone, code) => {
  return ajax.post(`${base}/api/itedu/v1/quicklogin?phone=${phone}&code=${code}`);
};

const uid = () => {
  return ajax.get(`${base}/api/itedu/v1/uid`);
};

const logout = () => {
  return ajax.get(`${base}/api/itedu/v1/logout`).then((ret) => {
    Notify({
      type: "success",
      message: "退出成功",
    });
    localStorage.clear();
    router.replace({ name: "courseList" });
  });
};

const logoutV2 = () => {
  return ajax.get(`${base}/api/itedu/v1/logout`);
};

export const userCenterApi = {
  // import时得花括号明确
  smscode: smscode,
  quicklogin: quicklogin,
  uid: uid,
  logout: logout,
  logoutV2: logoutV2,
};
