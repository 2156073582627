export default {
  isMobile: /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/i.test(navigator.userAgent),
  checkTypes(ext) {
    const extLowerCase = ext.toLowerCase();
    const pictureExts = ["jpg", "jpeg", "png", "gif", "bmp"];
    const docExts = ["pdf", "docx", "doc", "xlsx"];
    const audioExts = ["mp3", "wav", "aac", "ogg"];
    const videoExts = ["mp4", "rmvb", "wmv", "avi", "flv", "mov"];

    if (pictureExts.includes(extLowerCase)) {
      return "picture";
    } else if (docExts.includes(extLowerCase)) {
      return "document";
    } else if (audioExts.includes(extLowerCase)) {
      return "audio";
    } else if (videoExts.includes(extLowerCase)) {
      return "video";
    } else {
      return null;
    }
  },
  kebabCase(str) {
    const regExp = /([^-])([A-Z])/g;
    return str
      .replace(regExp, "$1-$2")
      .replace(regExp, "$1-$2")
      .toLowerCase();
  },
  status(route, types) {
    const routerMatchs = route.matched;
    let status = {};
    routerMatchs.forEach((item) => {
      status = item.meta
        ? types.reduce((init, cur) => {
            // eslint-disable-next-line no-prototype-builtins
            if (item.meta.hasOwnProperty(cur.field)) {
              item.meta[cur.field] ? (init[cur.field] = cur.return) : (init[cur.field] = "");
            }
            return init;
          }, status)
        : status;
    });
    return status;
  },
  setMessageData(item) {
    let data = {};
    switch (item.unitType) {
      case 0:
        data.message = item.cos.url;
        data.messageType = "cos";
        data.cos = item.cos;
        data.cos.cosType = this.checkTypes(item.cos.fileType);
        break;
      case 1:
        data.message = item.markDown.content;
        data.messageType = "markDown";
        break;
      case 2:
        data.message = item.html.content;
        data.messageType = "html";
        break;
      case 3:
        data.message = item.vod.fileName;
        data.messageType = "vod";
        data.vod = item.vod;
        break;
      case 4:
        data.message = item.practice.showTitle;
        data.messageType = "practice";
        data.practice = item.practice;
        break;
    }
    return data;
  },
};
