<template>
  <div class="login">
    <div class="empty"></div>
    <div class="widget">
      <div class="row">
        <span class="message">需要登录再学习哦～</span>
      </div>
      <div class="row">
        <div class="phone">
          <input class="phone-input" type="text" placeholder="手机号" v-model="phone" />
        </div>
      </div>
      <div class="row">
        <div class="verify">
          <input class="verify-code" type="text" placeholder="验证码" v-model="verifyCode" />
          <span class="get-code" @click="onGetSmsCode">
            {{ this.isSend ? `${this.seconds}秒` : "获取验证码" }}
          </span>
        </div>
      </div>
      <div class="row">
        <button class="btn-login" @click="onQuickLogin">登录 / 注册</button>
      </div>
    </div>
  </div>
</template>

<script>
// 专门用来做路由401判断
import { Notify } from "vant";
import { userCenterApi } from "@/api/userCenterApi.js";
const COUNT_DOWN_SECOND = 60;
export default {
  data() {
    return {
      phone: "",
      verifyCode: "",
      clock: null,
      isSend: false,
      seconds: COUNT_DOWN_SECOND,
      isShowLogin: false,
      toPath: "",
    };
  },
  mounted() {
    // this.$EventBus.$on('onLoginWidget', (val) => {
    //     this.isShowLogin = val;
    //     console.log('isShowLogin', this.isShowLogin);
    // });
  },
  methods: {
    onGetSmsCode() {
      if (this.isSend) {
        return;
      }
      if (this.phone.length != 11 || !this.phone.startsWith("1")) {
        Notify({
          type: "warning",
          message: "不要用假手机号骗我  ๑乛◡乛๑ (●´∀｀●)",
        });
        return;
      }
      this.isSend = true;
      userCenterApi
        .smscode(this.phone)
        .then(() => {
          this.onCountDown();
        })
        .catch((err) => {
          this.isSend = false;
        });

      // getSmsCodeApi(this.phone).then((ret) => {
      //     this.onCountDown();
      // });
    },
    onQuickLogin() {
      console.log("toPath", this.toPath);
      if (this.phone.length != 11 || !this.phone.startsWith("1") || this.verifyCode.length != 4) {
        Notify({
          type: "warning",
          message: "不填手机号和验证码...可是登录不了的啊  ๑乛◡乛๑ (●´∀｀●)",
        });
        return;
      }
      userCenterApi.quicklogin(this.phone, this.verifyCode).then((res) => {
        if (res.code == 0 && res.data) {
          userCenterApi.uid().then((uid_ret) => {
            if (uid_ret.code == 0 && uid_ret.data) {
              localStorage.clear();
              for (const key in uid_ret.data) {
                // eslint-disable-next-line no-prototype-builtins
                if (uid_ret.data.hasOwnProperty(key)) {
                  const element = uid_ret.data[key];
                  localStorage.setItem(key, element);
                }
              }
              window.location.href = this.toPath;
            } else {
              Notify({
                type: "danger",
                message: "获取用户信息失败",
              });
            }
          });
        }
      });
    },
    onCountDown() {
      this.isSend = true;
      this.seconds = COUNT_DOWN_SECOND;
      this.clock = setInterval(() => {
        if (this.seconds <= 1) {
          clearInterval(this.clock);
          this.isSend = false;
          this.seconds = COUNT_DOWN_SECOND;
        } else {
          this.seconds -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  .empty {
    flex: 1;
  }
  .widget {
    border-radius: 24px 24px 0px 0px;
    box-shadow: 4px 2px 10px 4px rgba(0, 0, 0, 0.5);
    clip-path: inset(-24px 0px 0px 0px);
    background: #ffffff;
    height: 480px;
    .row {
      width: 100%;
      display: flex;
      justify-content: center;
      .message {
        margin: 40px 0 30px 0;
        font-size: 20px;
        color: #000000;
        letter-spacing: 3.92px;
      }
      .phone {
        background: #f1f1f1;
        border-radius: 16px;
        //width: 19.375rem;
        //height: 3rem;
        width: 310px;
        height: 50px;
        font-size: 20px;
        .phone-input {
          width: 90%;
          border: none;
          background: inherit;
          border-radius: inherit;
          padding: 11px 0 11px 13px;
          letter-spacing: 2.8px;
          &::placeholder {
            color: #919191;
          }
        }
        margin: 0 0 30px 0;
      }
      .verify {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #f1f1f1;
        border-radius: 16px;
        // width: 19.375rem;
        // height: 3rem;
        width: 310px;
        height: 50px;
        font-size: 20px;
        margin: 0 0 106px 0;
        .verify-code {
          // height: 100%;
          width: 192px;
          border: none;
          background: inherit;
          border-radius: inherit;
          padding: 11px 0 11px 13px;
          letter-spacing: 2.8px;
          &::placeholder {
            color: #919191;
          }
        }
        .get-code {
          color: #4d52d1;
          line-height: 50px;
          width: 200px;
          font-weight: 500;
        }
      }
      .btn-login {
        width: 310px;
        height: 50px;
        background: #4d52d1;
        border-radius: 16px;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        border: none;
      }
    }
  }
}
</style>
