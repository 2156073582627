const index = () => import("@/views/course/index.vue");
const courseList = () => import("@/views/course/courseList.vue");
const userCourse = () => import("@/views/course/userCourse.vue");
const courseDetail = () => import("@/views/course/courseDetail.vue");
const courseSet = () => import("@/views/course/courseSet.vue");

export default {
  path: "/",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  redirect: {
    name: `courseList`,
  },
  children: [
    {
      path: "/",
      name: `courseList`,
      meta: {
        withoutAuth: true,
        title: "爱答教育科技 ",
      },
      component: courseList,
    },
    {
      path: "courseSet",
      name: `courseSet`,
      meta: {
        withoutAuth: true,
        title: "爱答教育科技 ",
      },
      component: courseSet,
    },
    {
      path: "userCourse",
      name: `userCourse`,
      meta: {
        withoutAuth: false,
        title: "我的课程",
      },
      component: userCourse,
    },
    {
      path: "courseDetail",
      name: `courseDetail`,
      meta: {
        withoutAuth: true,
        title: "爱答教育科技 课程详情",
      },
      component: courseDetail,
    },
  ],
};
