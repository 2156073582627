import axios from "axios";
import router from "@/router";

// 获取token
const getToken = () => {
  let token = localStorage.getItem("token") || "";
  return "Bearer " + token;
};

// 创建axios
const ajax = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL || "",
  timeout: 60000,
  transformResponse: [
    (data) => {
      return data;
    },
  ],
  validateStatus: (status) => {
    return status < 500;
  },
});

// 请求拦截
ajax.interceptors.request.use(
  (config) => {
    //console.log("req", config, config.data, config.param);
    config.headers.Authorization = getToken();
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截
ajax.interceptors.response.use(
  (res) => {
    //console.log("resp", res);
    let data = "";
    try {
      data = JSON.parse(res.data) || res.data;
    } catch (error) {
      data = res.data;
    }
    if (res.status == 200) {
      return data;
    } else if (res.status == 401) {
      // alert("未登录啊");
      // debugger;
      // return Promise.reject(data);
      localStorage.clear();
      router.push({ path: "/login" });
    }
  },
  (err) => {
    console.log(err);
    const { request } = err;
    // alert("请求出错");
    // 登录失效
    if (request.status == 401) {
      //localStorage.clear();
      //router.replace({ name: "login" });
      localStorage.clear();
      router.push({ path: "/login" });
    }
    return Promise.reject(err);
  }
);

export default ajax;
