const index = () => import("@/views/lab/index.vue");
const nplayerView = () => import("@/views/lab/nplayerView.vue");

export default {
  path: "/lab",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  children: [
    {
      path: "nplayer",
      name: `nplayer-view`,
      meta: {
        withoutAuth: false,
        title: "nplayer",
      },
      component: nplayerView,
    },
  ],
};
