const index = () => import("@/views/teach/index.vue");
const talk = () => import("@/views/teach/talk.vue");
const book = () => import("@/views/teach/book.vue");
const teachLectureNote = () => import("@/views/teach/teachLectureNote.vue");
const danmuVod = () => import("@/views/teach/danmuVod.vue");

export default {
  path: "/teach",
  name: "teach",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  children: [
    {
      path: "talk",
      name: `teach-talk`,
      meta: {
        withoutAuth: false,
        title: "爱答教育 学习中心",
      },
      component: talk,
    },
    {
      path: "danmu-vod",
      name: `teach-danmu-vod`,
      meta: {
        withoutAuth: false,
        title: "爱答教育 学习中心",
      },
      component: danmuVod,
    },
    {
      path: "teach-lecture-note",
      name: `teach-lecture-note`,
      meta: {
        withoutAuth: false,
        title: "爱答教育 学习中心",
      },
      component: teachLectureNote,
    },
    {
      path: "book",
      name: `teach-book`,
      meta: {
        withoutAuth: false,
        title: "爱答教育 学习中心",
      },
      component: book,
    },
  ],
};
