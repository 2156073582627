const index = () => import("@/views/tiku/index.vue");
const tikuHome = () => import("@/views/tiku/tikuHome.vue");
const tikuReport = () => import("@/views/tiku/tikuReport.vue");
const tikuQuestion = () => import("@/views/tiku/tikuQuestion.vue");
const tikuExerciseBook = () => import("@/views/tiku/tikuExerciseBook.vue");

export default {
  path: "/tiku",
  component: index,
  children: [
    {
      path: "/",
      name: `tikuHome`,
      meta: {
        withoutAuth: true,
        title: "爱答教育题库",
      },
      component: tikuHome,
    },
    {
      path: "tikuReport",
      name: `tikuReport`,
      meta: {
        withoutAuth: true,
        title: "爱答教育题库",
      },
      component: tikuReport,
    },
    {
      path: "tikuQuestion",
      name: `tikuQuestion`,
      meta: {
        withoutAuth: true,
        title: "爱答教育题库",
      },
      component: tikuQuestion,
    },
    {
      path: "tikuExerciseBook",
      name: `tikuExerciseBook`,
      meta: {
        withoutAuth: true,
        title: "爱答教育题库",
      },
      component: tikuExerciseBook,
    },
  ],
};
