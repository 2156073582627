/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";

import profileRouter from "@/router/userRouter.js";
import courseRouter from "@/router/courseRouter.js";
import teachRouter from "@/router/teachRouter.js";
import marketRouter from "@/router/marketRouter.js";
import payRouter from "@/router/payRouter.js";
import userLearnPlanRouter from "@/router/userLearnPlanRouter.js";
import userCosRouter from "@/router/userCosRouter.js";
import userHomeWorkRouter from "@/router/userHomeWorkRouter.js";
import tikuRouter from "@/router/tikuRouter.js";
import courseExtendRouter from "@/router/courseExtendRouter.js";
import labRouter from "@/router/labRouter.js";
import homeworkAssignRouter from "@/router/homeworkAssignRouter.js";

import util from "@/util/util.js";

Vue.use(VueRouter);

const routes = [
  homeworkAssignRouter,
  profileRouter,
  courseRouter,
  teachRouter,
  marketRouter,
  payRouter,
  userLearnPlanRouter,
  userCosRouter,
  userHomeWorkRouter,
  tikuRouter,
  courseExtendRouter,
  labRouter,
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      withoutAuth: true,
      title: "隐私策略",
    },
    component: () => import("@/views/privacy.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      withoutAuth: true,
      title: "关于",
    },
    component: () => import("@/views/about.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  //base: "tiku-jam-cms-page",
  routes,
});

import loginWidget from "@/components/loginWidget.vue";
const Login = Vue.extend(loginWidget); // 创建的是一个组件构造器，不是实例
const Message = {
  install: (toPath) => {
    let instance = new Login({}).$mount();
    instance.toPath = toPath;
    document.body.appendChild(instance.$el); // 添加dom元素
    Vue.nextTick(() => {
      // dom元素渲染完成后执行回调
      instance.visible = true;
    });
  },
};

router.beforeEach((to, from, next) => {
  if (to && to.meta) {
    document.title = to.meta.title;
  } else {
    document.title = "爱答教育";
  }

  const status = util.status(to, [{ return: true, field: "withoutAuth" }]);
  const withoutAuth = status.withoutAuth;
  if (withoutAuth || (!withoutAuth && localStorage.getItem("userId"))) {
    // console.log(to.path);
    next();
  } else {
    console.log("need login...", to);
    // Message.install(to.path);
    //Message.install(to.path);
    Message.install(to.fullPath);
    // Notify({
    //     type: 'warning',
    //     message: '需要登录再学习哦  ๑乛◡乛๑ (●´∀｀●)',
    // });
    // setTimeout(() => {
    //     next({ name: 'login' });
    // }, 1500);
  }
});

export default router;
