const index = () => import("@/views/courseExtend/index.vue");
const courseExtendList = () => import("@/views/courseExtend/courseExtendList.vue");
const courseExtendAddEdit = () => import("@/views/courseExtend/courseExtendAddEdit.vue");
const courseExtendView = () => import("@/views/courseExtend/courseExtendView.vue");

export default {
  path: "/courseExtend",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  children: [
    {
      path: "",
      name: `course-extend-index`,
      meta: {
        withoutAuth: false,
        title: "续课申请",
      },
      component: courseExtendList,
    },
    {
      path: "addEdit",
      name: `course-extend-add-edit`,
      meta: {
        withoutAuth: false,
        title: "续课申请",
      },
      component: courseExtendAddEdit,
    },
    {
      path: "view",
      name: `course-extend-view`,
      meta: {
        withoutAuth: false,
        title: "续课申请",
      },
      component: courseExtendView,
    },
  ],
};
