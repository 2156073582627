const index = () => import("@/views/userCos/index.vue");
const userCos = () => import("@/views/userCos/userCos.vue");

export default {
  path: "/userCos",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  children: [
    {
      path: "/",
      name: `userCos`,
      meta: {
        withoutAuth: false,
        title: "爱答教育 ",
      },
      component: userCos,
    },
  ],
};
