const index = () => import("@/views/userLearnPlan/index.vue");
const planPageList = () => import("@/views/userLearnPlan/planPageList.vue");
const planAddEdit = () => import("@/views/userLearnPlan/planAddEdit.vue");
const planView = () => import("@/views/userLearnPlan/planView.vue");

export default {
  path: "/userLearnPlan",
  component: index,
  // meta: {
  //     showLessonMenu: true,
  //     withoutAuth: true,
  // },
  children: [
    {
      path: "",
      name: `userLearnPlan-index`,
      meta: {
        withoutAuth: false,
        title: "学习规划",
      },
      component: planPageList,
    },
    {
      path: "addEdit",
      name: `userLearnPlan-add-edit`,
      meta: {
        withoutAuth: false,
        title: "学习规划",
      },
      component: planAddEdit,
    },
    {
      path: "view",
      name: `userLearnPlan-view`,
      meta: {
        withoutAuth: false,
        title: "学习规划",
      },
      component: planView,
    },
  ],
};
