const index = () => import("@/views/user/index.vue");
const profile = () => import("@/views/user/profile.vue");
const appDownload = () => import("@/views/user/appDownload.vue");
const addToScreenStep = () => import("@/views/user/addToScreenStep.vue");
const userRemark = () => import("@/views/user/userRemark.vue");
const userRemarkAdd = () => import("@/views/user/userRemarkAdd.vue");

export default {
  path: "/profile",
  name: "profile",
  component: index,

  children: [
    {
      path: "",
      name: "myProfile",
      meta: {
        withoutAuth: false,
        title: "爱答教育 用户中心",
      },
      component: profile,
    },
    {
      path: "appDownload",
      name: "appDownload",
      meta: {
        withoutAuth: false,
        title: "爱答教育 h5安装",
      },
      component: appDownload,
    },
    {
      path: "addToScreenStep",
      name: "addToScreenStep",
      meta: {
        withoutAuth: false,
        title: "爱答教育 h5安装",
      },
      component: addToScreenStep,
    },
    {
      path: "userRemark",
      name: "userRemark",
      meta: {
        withoutAuth: false,
        title: "爱答教育 用户星球",
      },
      component: userRemark,
    },
    {
      path: "userRemarkAdd",
      name: "userRemarkAdd",
      meta: {
        withoutAuth: false,
        title: "爱答教育 用户星球",
      },
      component: userRemarkAdd,
    },
  ],
};
